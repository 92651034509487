<template>
    <div>




        <div class="gantt-buttons" v-if="!mikro">
            <a href="#" @click.prevent="scrollToday" class="btn btn-thin" disabled="">Dnes</a>
            <div class="btn-group ml-5">
                <a @click.prevent="calendarDays()" href="#" :class="'btn btn-outline-dark '+ (viewType=='d' ? 'hovered':'')">{{ $t('motor.calendar.days')}}</a>
                <a @click.prevent="calendarWeeks()" href="#" :class="'btn btn-outline-dark '+ (viewType=='w' ? 'hovered':'')">{{ $t('motor.calendar.weeks')}}</a>
                <a @click.prevent="calendarMonths()" href="#" :class="'btn btn-outline-dark '+ (viewType=='m' ? 'hovered':'')">{{ $t('motor.calendar.months')}}</a>
            </div>
        </div>



        <div class="calendar-wrap" :style="`width:${cal_width}px`">
            <div class="calendar" :style="`width:${cal_width}px; height:${area_height}px`" id="osa-calendar">
                <div class="hours-grid" :style="`width:${area_width}px`">
                    <div :style="`height:${hour_height}px`" class="hour" v-for="h in (0, 24)" :key="h"></div>
                </div>


                <div class="kont months-view" :style="`width:${area_width}px`" v-if="viewType=='m'">
                      <div class="items">
                          <div :id="`popoverm-target-${i.id}`" :class="'cal-line-item '+i.class " v-for="i in items" :key="i.id" :style="`left:${i.x}px; top:${i.y}px;`" @click="showActivity(i.akt)">

                          </div>

                        <b-popover :target="`popoverm-target-${i.id}`" triggers="hover" placement="right" v-for="i in items" :key="i.key">
                          <div class="d-flex bublina-aktivity">
                            <img :src="ikonaAktivity(i.akt)" />
                            <div class="datum">{{df(i.akt.Time)}}</div>
                          </div>
                        </b-popover>


                      </div>
                    <div class="today-line" :style="`left: ${today_left}px`"></div>
                </div>


                <div class="kont week-view" :style="`width:${area_width}px`" v-if="viewType=='w'">
                    <div class="week" v-for="v in weeks" :key="v.id">
                    </div>
                    <div class="items">
                        <div :id="`popoverw-target-${i.id}`" :class="'cal-line-item '+i.class " v-for="i in items" :key="i.id" :style="`left:${i.x}px; top:${i.y}px;`" @click="showActivity(i.akt)" :title="i.title">
                        </div>

                      <b-popover :target="`popoverw-target-${i.id}`" triggers="hover" placement="right" v-for="i in items" :key="i.key">
                        <div class="d-flex bublina-aktivity">
                          <img :src="ikonaAktivity(i.akt)" />
                          <div class="datum">{{df(i.akt.Time)}}</div>
                        </div>
                      </b-popover>
                    </div>
                    <div class="today-line" :style="`left: ${today_left}px`"></div>
                </div>



                <!------------------------------------------------------------------------------------->
                <div class="kont days-view" :style="`width:${area_width}px`" v-if="viewType=='d'">

                    <div class="today-line" :style="`left: ${today_left}px`"></div>
                    <div class="items">
                        <div :id="`popoverd-target-${i.id}`" :class="'cal-line-item '+i.class " v-for="i in items" :key="i.id" :style="`left:${i.x}px; top:${i.y}px;`" @click="showActivity(i.akt)" :title="i.title"></div>
                    </div>

                  <b-popover :target="`popoverd-target-${i.id}`" triggers="hover" placement="right" v-for="i in items" :key="i.id">
                    <div class="d-flex bublina-aktivity">
                      <img :src="ikonaAktivity(i.akt)" />
                      <div class="datum">{{df(i.akt.Time)}}</div>
                    </div>
                  </b-popover>


                    <div :class="`day ${v.class}`" v-for="v in days" :key="v.id">
                        <div class="day-name"></div>
                    </div>

                </div>
            </div>

            <div class="kont hgridkont" id="hour-gridkont" :style="`height:${area_height}px`">
                <div class="hours-grid">
                    <div class="hour" v-for="h in [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]" :key="h" :style="`height:${hour_height}px`"><div class="hour-label" :style="`line-height:${hour_height}px`">{{h}}</div></div>
                </div>
            </div>

            <div class="horizontal-osa" id="timegrid-x"  :style="`width:${cal_width}px`">
                <div   class="kont timegrid-x week-view" :style="`width:${area_width+40}px;height:${area_height}px`" v-if="viewType=='w'">

                    <div class="months">
                        <div class="month" v-for="m in months" :key="m.id" :style="`width:${m.width}px`">
                            {{m.title}}
                        </div>
                        <div class="month-end"></div>
                    </div>

                    <div class="week" v-for="v in weeks" :key="v.id">
                        <div class="week-name">{{ v.date }}</div>
                    </div>
                </div>




                <div class="kont timegrid-x  days-view" :style="`width:${area_width}px`" v-if="viewType=='d'">
                    <div class="months" id="months-dayview-osa">
                        <div class="month" v-for="m in months" :key="m.id" :style="`width:${m.width}px`">
                            {{m.title}}
                        </div>
                        <div class="month-end"></div>
                    </div>
                    <div :class="`day ${v.class}`" v-for="v in days" :key="v.id">
                        <div class="day-name">{{ v.date }}</div>
                    </div>
                </div>


                <div class="kont timegrid-x  months-view" :style="`width:${area_width}px`" v-if="viewType=='m'">

                    <div class="years">
                        <div class="year" v-for="m in years" :key="m.id" :style="`width:${m.width}px`">
                            {{m.title}}
                        </div>
                    </div>

                    <div class="month" v-for="m in months" :key="m.id" :style="`width:${m.width}px`">
                        <div class="month-name">{{m.title}}</div>
                    </div>
                    <div class="month-end"></div>
                </div>



            </div>


        </div>

        <a href="#" data-toggle="modal"  data-target="#activityModal" id="spoustec-modalu"></a>
        <AktivityModal :akt="akt" class="modal fade" id="activityModal" tabindex="-1" role="dialog" aria-hidden="true"></AktivityModal>
    </div>
</template>

<script>

import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

    import AktivityModal from "./AktivityModal";
    const moment = require('moment');
    require('moment/locale/cs');
    moment.locale('cs');
    import _ from 'lodash'
    function sortamotora(a,b){
        if (!a.Time) return 0
        var t1 = moment(a.Time).unix()
        var t2 = moment(b.Time).unix()
        return t1>t2 ? 1 : -1
    }

    export default {
        name: "Calendar",
        props:['motor', 'mikro'],
        data(){
            return{
                hour_height:25,
                area_height: 400,
                acts:[],
                months:[],
                weeks:[],
                years:[],
                days:[],
                months_left:0,
                items:[],
                cal_width:600,
                area_width:2600,
                akt:[],
                viewType:'d',
                today_left:0,
                default_today_updated:false
            }
        },
        components: { AktivityModal},
        computed:{
            aktivity(){
                if (!this.acts) return []
                var akt = this.acts
                return akt.sort(sortamotora)
            },


        },
        mounted() {

            if (this.mikro){
                this.hour_height=10
                this.area_height=140

                this.cal_width = document.getElementById('mikro-kalendar-kont').clientWidth  - 32
                this.viewType='w'
                var top = 80
                document.getElementById('osa-calendar').scrollTop =top
                document.getElementById('hour-gridkont').scrollTop = top
            }


            this.$nextTick(function(){
                //console.log('mounted Kalendar, ', this.motor)
                if (this.motor && this.motor.id) {
                    this.acts = this.motor.activities
                    this.prepareData()
                }

                if (this.$parent.motor) {
                    this.acts = this.$parent.motor.activities
                    this.prepareData()
                }
            })


            //console.log('Kal motor mount', this.activities)
            this.$parent.$on('motorUpdated2', (data) => {
                //console.log('Kal motor updated  2', data, this.activities)
                this.acts = data.activities
                this.prepareData()
            })

            var that= this
            document.getElementById('osa-calendar').addEventListener('scroll', function() {
                that.repositionTimeosa()

            });

        },

        methods:{
          df: function (time, format) {
            moment.locale(this.$i18n.locale);
            if (!time) return '';
            var a = moment(time);
            return a.format(format ? format : "L LT")
          },

            throttledMethod: _.throttle(() => {
                console.log('I get fired every two seconds!')
            }, 2000),

            repositionTimeosa: _.throttle(() => {
                var l = document.getElementById('osa-calendar').scrollLeft
                var top = document.getElementById('osa-calendar').scrollTop
                document.getElementById('hour-gridkont').scrollTop = top
                document.getElementById('timegrid-x').scrollLeft = l
            },200),


            calendarMonths(){
                this.viewType = 'm'
                this.prepareMonths()
                this.scrollToday()

            },
            calendarWeeks(){
                this.viewType = 'w'
                this.computeWeeks()
                this.scrollToday()

            },
            calendarDays(){
                this.viewType = 'd'
                this.prepareDays()
                this.scrollToday()

            },
            prepareData(){
                if (this.$i18n) {
                    moment.locale(this.$i18n.locale);
                }
                var w = 300
                if (this.mikro)
                     w = document.getElementById('mikro-kalendar-kont').clientWidth -32
                else
                    w  = document.getElementById('motor-card').clientWidth

                this.cal_width = w
                //console.log("prepar e data",this.viewType,  w)

                if (this.viewType == 'd') this.prepareDays()
                if (this.viewType == 'w') this.computeWeeks()
                if (this.viewType == 'm') this.prepareMonths()

                if (this.today_left>0 && !this.default_today_updated){
                    this.default_today_updated=true
                    this.scrollToday()
                }
            },

            scrollToday(){
                var sc = this.today_left - (this.cal_width/2)
                this.$nextTick(function(){
                    document.getElementById('osa-calendar').scrollLeft = sc
                })
                this.repositionTimeosa()
            },


            prepareDays(){
                const day_width=30
                const arr=[]
                if (!this.aktivity || this.aktivity.length==0) return
                var a1= this.aktivity[0]
                var aEnd = this.aktivity[ this.aktivity.length-1 ];
                //console.log('t1', moment(a1.Time).format('Y-MM-DD') )
                var t = moment(a1.Time).startOf('month').subtract(1, 'month')
                const t_start = t.unix()
                var t_end = moment(aEnd.Time).endOf('month').add(2, 'month').add(1,'day');

                this.area_width = (moment(t_end).endOf('month').unix() -  t_start)*day_width/(24*3600)
                this.today_left = (moment().unix() -  t_start)*day_width/(24*3600)
                var m = moment(t).startOf('month')
                this.months=[]
                var mleft=0;
                var mw = ''
                //console.log('offset', ofs);
                while(t.unix() <= t_end.unix()) {
                    arr.push({
                        id: t.format('YY-MM-D'),
                        key: 'd'+t.format('YY-MM-D'),
                        date: t.format('D'),
                        class: 'w'+ t.weekday()
                    })

                    if (mw != t.format('M')){

                        this.months.push({
                            id: m.format('m-Y-MM'),
                            title: m.format('MMMM YYYY'),
                            left: mleft,
                            width: (m.daysInMonth())*day_width
                        })
                        m.add(1,'month')
                        mleft=0;
                        mw = t.format('M')

                    }

                    t.add(1, "day")
                }
                this.days = arr
                this.items=[]

                let y=0
                let used = {}
                for(var i in this.aktivity){
                    let a = this.aktivity[i]
                    let t1 = moment(a.Time).startOf('day').unix()
                    let x = (t1 - t_start)*day_width/(24*3600)
                    y = moment(a.Time).hour() * 25
                    let key = moment(a.Time).format('Y-MM-D-H')
                    if (used[key]) {
                        y+= 20 * used[key]
                        used[key]++;
                    } else used[key]=1
                    this.items.push({
                        id: a.id,
                       key: `akt${a.id}`,
                        akt: a,
                        title: key,
                        x: x,
                        y: y+1,
                        class: 'activity-'+a.ActivityType
                    })

                }

            },

            prepareMonths(){
                this.items=[]
                const day_width=2
                const arr=[]
                if (!this.aktivity || this.aktivity.length==0) return
                var a1= this.aktivity[0]
                var aEnd = this.aktivity[ this.aktivity.length-1 ];
                //console.log('t1', moment(a1.Time).format('Y-MM-DD') )
                var t = moment(a1.Time).startOf('month').subtract(1, 'month').startOf("year")
                const t_start = t.unix()
                var ofs =  t.dayOfYear()
                var t_end = moment(aEnd.Time).endOf('year')

                this.area_width = (moment(t_end).endOf('month').unix() -  t_start)*day_width/(24*3600)

                this.today_left = (moment().unix() -  t_start)*day_width/(24*3600)

                var m = moment(t).startOf('month')
                this.years=[]
                var mw = ''
                var mleft=ofs;
                //console.log('offset', ofs);
                while(t.unix() < t_end.unix()){
                    arr.push({
                        id:  t.format('YY-MM-D'),
                        title: t.format('MMMM'),
                        width: t.daysInMonth()*day_width

                    })
                    //console.log('add w', mw, t.format('YY-MM-DD') )
                    if (mw != t.format('Y')){

                        this.years.push({
                            id: m.format('m-Y-MM'),
                            key: "mo-"+m.format('m-Y-MM'),
                            title: m.format('YYYY'),
                            left: mleft,
                            width: 365.25*day_width
                        })
                        m.add(1,'month')
                        mleft+=365.25*day_width;
                        mw = t.format('Y')

                    }
                    t = t.add(1, "month")
                }

                this.months = arr;



                let used={}
                let y=0
                for(var i in this.aktivity){
                    let a = this.aktivity[i]
                    let t1 = moment(a.Time).unix()
                    let x = (t1 - t_start)*day_width/(24*3600)
                    y = moment(a.Time).hour() * 25
                    let key = moment(a.Time).format('Y-M-H')
                    if (used[key]) {
                        y+= 20 * used[key]
                        used[key]++;
                    } else used[key]=1


                    this.items.push({
                        id: a.id,
                        akt: a,
                        key: 'itp'+a.id,
                        title: key,
                        /*   title: this.activityTypeName(a),
                           icon: this.ikonaAktivity(a),*/
                        x: x,
                        y: y,
                        class: 'activity-'+a.ActivityType
                    })
                }
            },

            computeWeeks(){

                const arr=[]
                if (!this.aktivity || this.aktivity.length==0) return
                var a1= this.aktivity[0]
                var aEnd = this.aktivity[ this.aktivity.length-1 ];
                //console.log('t1', moment(a1.Time).format('Y-MM-DD') )
                var t = moment(a1.Time).startOf('week').subtract(1, 'day').startOf('month')
                const t_start = t.unix()
                // console.log('korekce', t.format('Y-MM-D'))
                var ofs = 0// t.format('DD') * 10 -10
                var t_end = moment(aEnd.Time).startOf('week').add(24, 'week');

                this.area_width = (moment(t_end).endOf('month').unix() -  t_start)/(24*360)

                this.today_left = (moment().unix() -  t_start)*10/(24*3600)

                var m = moment(t).startOf('month')
                this.months=[]
                var mw = ''
                var mleft=ofs;
                //console.log('offset', ofs);
                while(t.unix() < t_end.unix()){
                    arr.push({
                        id:  t.format('YY-MM-D'),
                        date: t.format('W')

                    })
                    //console.log('add w', mw, t.format('YY-MM-DD') )
                    if (mw != t.format('M')){

                        this.months.push({
                            id: m.format('m-Y-MM'),
                          key: 'wek'+m.format('m-Y-MM'),
                            title: m.format('MMMM YYYY'),
                            left: mleft,
                            width: m.daysInMonth()*10-mleft

                        })
                        m.add(1,'month')
                        mleft=0;
                        mw = t.format('M')

                    }
                    t = t.add(1, "week")
                }

                this.weeks = arr;
                this.items=[]
                let used ={} ;
                let y=0
                for(var i in this.aktivity){
                    let a = this.aktivity[i]
                    let t1 = moment(a.Time).unix()
                    let x = (t1 - t_start)/(24*360)

                    y = moment(a.Time).hour() * this.hour_height
                    let key = moment(a.Time).format('Y-W-H')
                    if (used[key]) {
                        x+= 20 * used[key]
                        used[key]++;
                    } else used[key]=1

                    this.items.push({
                        id: a.id,
                        key:`wekit${a.id}`,
                        akt: a,
                        title:  moment(a.Time).format('Y-MM-DD'),
                        /*icon: this.ikonaAktivity(a),*/
                        x: x,
                        y: y,
                        class: 'activity-'+a.ActivityType
                    })

                }

            },


            showActivity(activity){
                this.akt = activity
                document.getElementById('spoustec-modalu').click()
            },
            ikonaAktivity(a){
                var akt = parseInt(a.ActivityType);
                if ( akt == 4 ) return "/img/mob/lubricationInterval.png";
                if ( akt == 5 ) return "/img/aktivita-prevent-mereni.png";
                if (akt==6) return "/img/aktivita-havarijni-servis.png";

                if ( akt == 2 ) return "/img/mob/Commissioning.png";

                if (akt==8) return "/img/others.png";

                if (akt==3) return "/img/serviceInterval.png"

                if (akt<2)return "/img/aktivita-vyroba.png";

                return "/img/aktivita-prevent-udrzba.png";
            },
            activityTypeName(a){
                if (typeof this.$t == 'undefined') return '?';
                var akt = parseInt(a.ActivityType);

                if (akt==0 )return this.$t('m.PExpedition.Expedice')
                if (akt==1 )return this.$t('m.PViewEngine.Instalace')
                if (akt==2 )return this.$t('m.PCommissioning.UvedeníDoProvozu')
                if (akt==3 )return this.$t('m.PViewEngine.PreventivníÚdržba')
                if (akt==4 )return this.$t('m.PViewEngine.PViewEngine_PreventivníMazání')
                if (akt==5 )return this.$t('m.PViewEngine.PViewEngine_PreventivníMěření')
                if (akt==6 )return this.$t('m.PDefect.Porucha')
                if (akt==7 )return this.$t('m.POther.Ostatní')
                if (akt==8 )return this.$t('m.POther.Ostatní')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .calendar-wrap{
        position:relative;
        padding-top:1px;
        margin-top:1em;

    }

    .kont.hgridkont{
        position:absolute;

        left:-20px;
        top:56px;
        height: 400px;
        overflow: hidden;
        .hours-grid{ height:700px;}
        .hour{padding-left:2px; width:25px}
        width:20px;

    }

    .horizontal-osa{
        position: absolute;
        top:4px;
        left:0;
        height: 55px;
        overflow: hidden;
        .days-view{
            .day{
                margin-top:30px;
                border-top:none;
            }
        }
    }

    .kont.timegrid-x{
        border-top:1px solid #ccc;

    }

    .hours-grid{

        position:absolute;
        top:0px;
        height:calc(24*25px);
        left:0;
        min-width: 30px;
        .hour{
            height:25px;
            border-bottom: 1px solid #dccc;

            .hour-label{
                margin-left:0px;
                line-height:25px;
            }
        }


    }
    .kont{
        position: relative;

        height: calc(24*25px);
        display: flex;
        .week{
            width:70px;
            border-right: 1px solid #ddd;
            text-align: center;
            margin-top:30px;
            .week-name{
                border-bottom:1px solid silver;
            }
        }
    }
    .months{

        position:absolute;
        display: flex;
        .month{
            min-width: 280px;
            border-right: 1px solid silver;
            text-align: center;
            border-bottom: 1px solid silver;
            height:30px;
            line-height: 30px;
        }
    }

    .calendar{
        .week{
            margin-top:0;
        }
        position: relative;
        margin-top:55px;

        width:500px;

        overflow-x:auto;
        overflow-y: auto;

        height:400px;

        .items{
            position:absolute;
            top:0px;
            left:0;
            .cal-item{
                border-left:2px solid black;

                position: absolute;
                cursor: pointer;

                .item-content {
                    position:relative;
                    width:40px;
                    height:40px;
                    left: -20px;
                    top:10px;
                    box-shadow: 0px 0px 4px #ccc;
                    background: white;
                    border-radius: 20px;
                    overflow: hidden;
                    img {
                        height: 30px;

                        margin-right: 5px;
                        margin-left: 5px;
                        margin-top: 5px;

                    }
                }
            }
        }
        .cal-line-item{

        }
    }

    .days-view{
        .day{
            width:30px;
            border-right: 1px solid #ddd;
            text-align: center;
            //margin-top:30px;
            .day-name{
                border-bottom:1px solid silver;
                background: whitesmoke;

            }


        }
        .w5, .w6{
            background: #e9e9e9;
            .day-name {color:darkred; background: #e9e9e9;}
        }
        .months{
            background: whitesmoke;
        }

        .cal-line-item{
            width:30px;

        }
    }
    .week-view{
        .cal-line-item{
            width:30px;
        }
    }
    .months-view{
        .years{
            display:flex;
            position:absolute;
            top:0;
            left:0;
            height:30px;
        }
        .year{
            min-width:365px;

            border-right: 1px solid silver;
            text-align: center;
            border-bottom: 1px solid silver;
            height:30px;
            line-height: 30px;

        }
        .month{
            margin-top:30px; border-right: 1px solid silver;
            .month-name{
                text-align: center;
                border-bottom: 1px solid silver;
            }
        }

        .month-end{
            background:silver;
        }

    }




    .cal-line-item{
        position:absolute;
        background-color: #5a5959;
        width:30px;
        height:24px;
        cursor: pointer;
        box-shadow: 0 0 2px rgba(44,44,44,0.4);
    }
    .activity-2{
        background-color: #11cf33;
    }
    .activity-3{
        background-color: #ffff00;
    }
    .activity-4{
        background-color: #cc0000;
    }
    .activity-5{
        background-color: #0052dc;
    }
    .activity-6{
        background-color: #cc9930;
    }
    .activity-7{
        background-color: magenta;
    }
    .activity-8{
        background-color: #bbb;
    }

    .today-line{
        width:1px;
        height: calc(25px*24);
        background-color: #ff2525;
        position: absolute;
        top:0px;
    }

    .gantt-buttons{
        margin-top:15px;

        .btn{
            padding:2px 10px;
            font-size:1em
        }
        .hovered{
            background: #666;
            color:white;
        }

    }


    .mikro-calendar{
        padding-left:32px;
        .calendar{
            overflow: hidden;
        }
    }


    .bublina-aktivity{
      img{ width:30px;}
      .datum{
        line-height:30px;
        margin-left:10px;
      }
    }
</style>
